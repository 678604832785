._loginRegister {
  text-align: center;
}

.column {
  float: left;
  width: 50%;
  height: 100%;
  background-color: white;
}

._loginRegister:after {
  content: "";
  display: table;
  clear: both;
}

.right {
  height: 438px;
}

.account-settings-header {
  margin: 0;
  margin-left: 8px;
}

@media (max-width: 700px) {
  .footer {
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .column {
    width: 100%;
  }
}
