.card {
  box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-text {
  text-align: justify;
  text-transform: capitalize;
}

.card-title {
  margin-top: 0px;
}
