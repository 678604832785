.footer {
  /*display: flex;*/
  display: none;
  justify-content: space-between;
  padding: 1.1rem;
  color: #000;
  box-shadow: 0 0 4px rgb(0 0 0 / 12%), 0 2px 4px rgb(0 0 0 / 20%);
}

img {
  max-width: 280px;
  max-height: 60px;
}

@media (max-width: 700px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
}
